/* Estilos base para dispositivos móviles */

.renders {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

.gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Usa repeat para tres columnas */
  gap: 5px; /* Esto debe crear un espacio de 100px entre filas y columnas */
}

.gallery > div {
  text-align: center;
  padding: 2px;
}

img {
  border-radius: 15px;
  object-fit: cover;
  max-width: 100%; /* Asegúrate de que se ajuste al contenedor */
  height: auto; /* Mantiene la proporción */
}

.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.modal-content {
  position: relative;
  left: 5%;
}
.modal-content img {
  max-width: 90%;
  max-height: 90%;
  margin: auto;
  border: 2px solid rgba(255, 255, 255, 0.315);
  border-radius: 25px; /* Opcional: agregar borde */
}

.encabezado {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
}
.seleccion {
  color: rgba(255, 255, 255, 0.295);
  transition: all 200ms;
  font-size: 12px;
}
.seleccion:hover {
  color: rgb(4, 202, 202);
}
/* Estilos para tabletas (pantallas medianas) */

@media (min-width: 768px) {
  .gallery {
    display: grid;
    grid-template-columns: auto auto auto auto;
  }

  .gallery > div {
    display: flex;
    justify-content: center; /* Centra la imagen en el div */
    align-items: center; /* Centra la imagen verticalmente */

  }

  img {
    border-radius: 15px;
    object-fit: cover;
    max-width: 300px; /* Asegúrate de que se ajuste al contenedor */
    height: auto; /* Mantiene la proporción */
  }
  .modal {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.932);
    text-align: center;
    z-index: 1000;
    transition: all 300ms;
  }
  .modal-content {
    text-align: center;
    margin: auto;
    width: 100vh;
    left: -45px;
  }
  .modal-content img {
    max-width: 90%;
    max-height: 100%;
    border: 2px solid rgba(255, 255, 255, 0.315);
    border-radius: 25px; /* Opcional: agregar borde */
  }

  .encabezado {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
  }
  .seleccion {
    color: rgba(255, 255, 255, 0.295);
    transition: all 200ms;
  }
  .seleccion:hover {
    color: rgb(4, 202, 202);
  }
}

/* Estilos para escritorio (pantallas grandes) */
@media (min-width: 1024px) {
}
