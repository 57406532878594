.calculadora {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: antiquewhite;
}

.visor {
  border-radius: 15px;
  height: 100px;
  color: black;
  font-size: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding: 5px;
}

.numeros {
  display: grid;
  grid-template-columns: auto auto auto auto;
  height: auto;
  border-radius: 10px;
  gap: 5px;
  padding: 5px;
}

.botones {
  width: 50px;
  height: 50px;
  background-color: rgb(95, 94, 94);
  align-items: center;
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 18px;
  border-radius: 25%;
  transition: all 250ms;
}
.botones:hover {
  background-color: rgb(179, 179, 179);
  cursor: pointer;
}

.botones:active {
  font-size: 12px;
}

button {
  background: none;
  border: none;
}

@media (min-width: 768px) {
}

/* Estilos para escritorio (pantallas grandes) */
@media (min-width: 1024px) {
    .calculadora {
        display: flex;
        flex-direction: column;
        border-radius: 20px;
        background-color: antiquewhite;
        max-width: 225px;
      }
      
      .visor {
        border-radius: 15px;
        height: 100px;
        color: black;
        font-size: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        padding: 5px;
      }
      
      .numeros {
        display: grid;
        grid-template-columns: auto auto auto auto;
        height: auto;
        border-radius: 10px;
        gap: 5px;
        padding: 5px;
      }
      
      .botones {
        width: 50px;
        height: 50px;
        background-color: rgb(95, 94, 94);
        align-items: center;
        text-align: center;
        display: flex;
        justify-content: center;
        font-size: 18px;
        border-radius: 25%;
        transition: all 250ms;
      }
      .botones:hover {
        background-color: rgb(179, 179, 179);
        cursor: pointer;
      }
      
      .botones:active {
        font-size: 12px;
      }
      
      button {
        background: none;
        border: none;
      }
      
}
