/* Estilos base para dispositivos móviles */
.admin {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.sesion{
 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: space-around;
  margin: auto;
  width: 100%;
}

.sesion > button{
  
  cursor: pointer;
  background-color: rgb(82, 2, 2);
  border: none;
  color: white;
  border-radius: 10px;
  height: 40px;
  transition: all 250ms;
}
.sesion > button:hover{

  background-color: red;
}

/* Estilos para tabletas (pantallas medianas) */

@media (min-width: 768px) {
}

/* Estilos para escritorio (pantallas grandes) */
@media (min-width: 1024px) {

  .sesion{

    margin: 30px;
  }

  .sesion > button{
    
    cursor: pointer;
    background-color: rgb(82, 2, 2);
    border: none;
    color: white;
    border-radius: 10px;
    height: 40px;
    transition: all 250ms;
  }
  .sesion > button:hover{

    background-color: red;
  }
}
