a{

    text-decoration: none;
    color: white;
    font-weight: bold;
    cursor: pointer;
    
}

@media (min-width: 768px) {
}

/* Estilos para escritorio (pantallas grandes) */
@media (min-width: 1024px) {


.footer{

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

    
}