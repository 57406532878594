/* Estilos base para dispositivos móviles */
body {
  overflow-x: hidden; /* Oculta cualquier desbordamiento horizontal */
  background: linear-gradient(to bottom right, #012220, #000000, #000000);
  color: rgb(199, 196, 196);
  width: 100%;
  padding: 10px;
  height: 100%;
}

img {
  max-width: 100%; /* Hace que las imágenes sean responsivas */
  height: auto;
}

* {
  box-sizing: border-box;
  flex-wrap: wrap;
}

/* Estilos para tabletas (pantallas medianas) */

@media (min-width: 768px) {

}

/* Estilos para escritorio (pantallas grandes) */
@media (min-width: 1024px) {
 

  .App{
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
    justify-content: center;

  }
}
