/* Estilos base para dispositivos móviles */

.boxCrearAdmin {
  min-height: 100vh;
  display: flex;
  overflow-y: hidden;
}

.crearAdmin {
  width: 100%;
  background: rgba(13, 164, 202, 0.123);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 7px;
  height: auto;
  margin: auto;
  padding: 5px;
}
.adminUser {
  margin: auto;
  position: relative;
  color: white;
  text-align: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
}


.crearAdminForm{
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


.input-group {
  display: flex;
  flex-direction: row;
  padding: 5px;
  margin: 5px;
  height: auto;
  font-size: 25px;
  border-radius: 15px;
}

.input-group-append {
  background-color: rgba(37, 68, 80, 0.692);
  color: white;
  padding: 5px;
  min-width: 40px;
  text-align: center;
  border-radius: 5px 0 0 5px;
}

.botonAdmin {
  background-color: rgb(37, 68, 80);
  border: none;
  height: 40px;
  border-radius: 8px;
  color: azure;
  width: 80px;
  margin: 18px;
}

.botonAdmin:hover {
  background-color: rgba(37, 45, 80);
}

.indicators {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  text-align: start;
}

#botonBox {
  text-align: center;
}
/* Estilos para tabletas (pantallas medianas) */

@media (min-width: 768px) {
}

/* Estilos para escritorio (pantallas grandes) */
@media (min-width: 1024px) {
}
