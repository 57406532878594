/* Estilos base para dispositivos móviles */

.habilidades {
  margin: 30px 5px 0 5px;
  display: flex;
  flex-direction: column;
}

.habContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  height: auto;
}

.lenguajes,
.rendering,
.graphic {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  align-items: center;
  list-style: none;
  margin: 0;
  width: 100%;
  border-bottom: 1px solid;
  border-image: linear-gradient(
      to right,
      rgba(4, 202, 202, 0) 10%,
      rgba(4, 202, 202, 0.8) 50%,
      rgba(4, 202, 202, 0) 90%
    )
    1;
}

.lenguajes li {
  margin: 2px 6px;
}

.logosPr {
  max-width: 30px;
}
.logosBs {
  max-width: 60px;
}

.rendering li,
.graphic li {
  margin: 10px 6px;
}
/* Estilos para tabletas (pantallas medianas) */

@media (min-width: 768px) {
}

/* Estilos para escritorio (pantallas grandes) */
@media (min-width: 1024px) {
  .habilidades {
    margin-top: 40px;
  }
  .logosPr {
    max-width: 80px;
  }
  .logosBs {
    max-width: 80px;
  }
  .rendering li,
  .graphic li {
    margin: 10px 16px;
  }
  .lenguajes li {
    margin: 2px 16px;
  }
}
