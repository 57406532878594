/* Estilos base para dispositivos móviles */
.proyectos {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.card {
  width: 100%;
  height: auto;
  border: 2px solid rgba(255, 255, 255, 0.226);
  border-radius: 3%;
  margin: 12px 0;
  padding: 2px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 2fr;
  gap: 5px;
}

.left-column {
  align-items: center;
  justify-content: center;
  margin: auto;
  height: auto;
}

.left-column img {
  border-radius: 3%;
  max-width: 150px;
  margin: 1px 3px;
}

.right-column {
  display: grid;
  align-items: center;
  width: 100%;
  border-radius: 25px;
  padding: 0 5px;
}

.right-column h4 {
  margin: 1px;
  font-size: 18px;
}

.right-column p {
  margin: auto;
  padding: 3px;
}

/* Estilos para tabletas (pantallas medianas) */

@media (min-width: 768px) {
}

/* Estilos para escritorio (pantallas grandes) */
@media (min-width: 1024px) {
  .card {
    width: 100%;
    height: auto;
    border: 2px solid rgba(255, 255, 255, 0.11);
    border-radius: 10px;
    margin: 12px 0;
    padding: 2px;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 0.5fr 4fr;
  }

  .left-column {
    align-items: center;
    justify-content: center;
    margin: auto;
    height: auto;
  }

  .left-column img {
    margin: 5px;
  }

  .right-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 25px;
    padding: 0 5px;
  }
  .right-column h4 {
    font-size: 22px;
  }

  .right-column p {
    font-size: 17px;
    padding: 0;
  }
}
