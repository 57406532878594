.productos-favoritos {
  max-width: 150px;
  text-align: center;
  margin: auto;
}

.productos-favoritos h2 {
  color: black;
}

.productos-favoritos p {
  color: black;
}

.box-favoritos {
  margin: 10px;
  background-color: white;
  border-radius: 10px;
}

.delete {
  background-color: rgb(168, 7, 7);
  color: white;
  border-radius: 4px;
  height: auto;
  padding: 5px;
  margin: 5px;
  font-size: 12px;
  cursor: pointer;
}

.delete:hover {
  background-color: rgb(212, 36, 36);
}

.delete:active {
  transform: scale(0.9);
}
