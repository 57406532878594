
/* Estilos base para dispositivos móviles */
.biography{

    margin: 50px 10px 0 10px;
}

.biography p{

    text-align:justify;
    

}


/* Estilos para tabletas (pantallas medianas) */

@media (min-width: 768px){


}

/* Estilos para escritorio (pantallas grandes) */
@media (min-width: 1024px){

}