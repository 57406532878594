/* Estilos base para dispositivos móviles */
.perfil {
  width: 100%;
  height: auto;
  border: 1px solid grey;
  padding: 5px;
  margin: auto;
}

.boxForm {
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.formPerfil {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.formInput {
  background-color: rgb(192, 190, 190);
  color: black;
  padding: 5px;
  border-radius: 8px;
}
.btnAgregar {
  background-color: rgb(37, 68, 80);
  border: none;
  height: 40px;
  border-radius: 8px;
  color: azure;
  width: 80px;
  margin: 18px;
}
.btnEliminar {
  background-color: rgb(80, 37, 37);
  border: none;
  height: 40px;
  border-radius: 8px;
  color: azure;
  width: 80px;
  margin: 18px;
}

.btnAgregar:hover {
  background-color: rgba(37, 45, 80);
}

.btnEliminar:hover {
  background-color: rgb(150, 13, 13);
}

.boxMuestra {
  max-width: 150px;
  margin: 10px;
}

.listaPerfil {
  list-style: none;
  text-decoration: none;
  text-align: center;
}

.listaPerfil li{
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



/* Estilos para tabletas (pantallas medianas) */

@media (min-width: 768px) {
}

/* Estilos para escritorio (pantallas grandes) */
@media (min-width: 1024px) {
}
