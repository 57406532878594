
.carrito {
  text-align: center;
  padding: 10px;
  margin: 80px 10px;
  background: rgb(238, 236, 236);
  overflow-y: auto;
  flex-wrap: nowrap;
  height: 80vh;
}
.carrito h3 {
  color: rgb(59, 59, 59);
  margin: auto;
  cursor: pointer;
}
.cartIcon {
  color: rgb(61, 61, 61);
  font-size: 25px;
  cursor: pointer;
}
.heart {
  cursor: pointer;
}

.heartActivo {
  color: rgb(182, 5, 5);
}

.totalItems {
  background: rgb(182, 5, 5);
  top: 12px;
  left: 42px;
  position: relative;
  border-radius: 50%;
  padding: 3px 5px;
  font-size: 12px;
  font-weight: bold;
  color: aliceblue;
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  position: relative;
  width: 100%;
  padding: 15px;
}

.search {
  width: 80%;
  border-radius: 10px;
  border: none;
  margin: auto;
  background-color: rgb(209, 207, 207);
  padding: 5px;
  text-align: center;
}

.segmentos {
  list-style: none;
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 0;
  color: black;
}

.segmentos li {
  margin: 10px;
}

/* Estilos para tabletas (pantallas medianas) */

@media (min-width: 768px) {
}
/* Estilos para escritorio (pantallas grandes) */
@media (min-width: 1024px) {
}
