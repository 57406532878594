.productos-shop {
    max-width: 150px;
    margin: auto;
    text-align: center;
  }
  
  .productos-shop h2 {
    color: black;
  }
  
  .productos-shop p {
    color: black;
  }
  
  .box-shop {
    margin: 10px;
    background-color: white;
    border-radius: 10px;
  }

  
.proceder {
    background-color: rgb(6, 131, 23);
    color: white;
    border-radius: 4px;
    height: auto;
    padding: 5px;
    margin: 5px;
    font-size: 12px;
    cursor: pointer;
  }
  
  .proceder:hover {
    background-color:  rgb(6, 131, 23);
  }
  
  .proceder:active {
    transform: scale(0.9);
  }