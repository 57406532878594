.notas {
  display: grid;
  grid-template-columns: auto;
  width: 400px;
  gap: 5px;
}

.recuadro {
  width: 100%;
  height: auto;
  border: 1px solid grey;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  text-align: center;
  background-color: white;
  color: black;
  margin: auto;
}
.recuadro textarea {
  background: rgb(218, 217, 217);
  border: none;
  border-radius: 10px;
  resize: none;
  color: rgb(0, 0, 0);
  padding: 5px;
  width: 90%;
  margin: 10px;
}

.apartado {
  display: flex;
  flex-direction: column; /* Apila las notas y el botón */
  width: 100%;
  text-align: center;
}

.notasGrid {
  display: grid;
  grid-template-columns: auto auto; /* Dos columnas para las notas */
  gap: 10px; /* Espacio entre notas */
  justify-content: center; /* Centra las notas en el contenedor */
}
.botonesNotas {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.botonesNotas button {
  color: white;
  margin: 10px;
  border-radius: 5px;
  padding: 5px;
}

.guardar {
  background-color: green;
  transition: all 250ms;
  cursor: pointer;
}

.borrar {
  background-color: red;
  transition: all 250ms;
  cursor: pointer;
}

.notita {
  /* Color amarillo estilo papel */
  color: black;
  display: flex;
  border-radius: 3px;
  width: 100px;
  min-height: 150px;
  min-width: 150px;
  padding: 4px;
  margin: 5px;
  position: relative; /* Necesario para posicionar el pseudo-elemento */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Sombra para dar profundidad */
  font-family: "Comic Sans MS", cursive; /* Ejemplo de fuente manuscrita */
  overflow: hidden;
}

.notita p {
  margin: auto;
  text-decoration: underline;
}

.notita::before {
  content: "";
  position: absolute;
  width: 20px; /* Tamaño del pliegue */
  height: 20px;
  background-color: rgb(
    0,
    0,
    0
  ); /* Color ligeramente diferente para el pliegue */
  bottom: 0;
  right: 0;
  clip-path: polygon(0 0, 100% 0, 100% 100%);
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.2); /* Sombra para el pliegue */
  z-index: 1; /* Coloca el pliegue sobre el borde de la nota */
  border-top: 1px solid rgba(0, 0, 0, 0.1); /* Línea sutil para el borde del pliegue */
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  transform: rotate(90deg);
}

#eliminarNota {
  width: 20px;
  height: 20px;
  padding: 0;
  position: absolute;
  margin: 2px;
}

.fecha {
  width: 100%;
  padding: 0;
  font-size: 10px;
  position: absolute;
  left: -6px;
  bottom: 0;
  font-weight: bold;
}

.todasDelete {
  width: 100px;
  height: 30px;
  margin: 10px auto; /* Centra horizontalmente */
  align-self: center; /* Asegura que esté en la parte inferior */
}

/* Estilos para tabletas (pantallas medianas) */

@media (min-width: 768px) {
}
/* Estilos para escritorio (pantallas grandes) */
@media (min-width: 1024px) {
  .apartado {
    display: flex;
    flex-direction: column; /* Apila las notas y el botón */
    width: 100%;
    text-align: center;
  }

  .notasGrid {
    display: grid;
    grid-template-columns: auto auto; /* Dos columnas para las notas */
    gap: 10px; /* Espacio entre notas */
    justify-content: center; /* Centra las notas en el contenedor */
  }
  .botonesNotas {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .botonesNotas button {
    color: white;
    margin: 10px;
    border-radius: 5px;
    padding: 5px;
  }

  .guardar {
    background-color: green;
    transition: all 250ms;
    cursor: pointer;
  }

  .guardar:hover {
    transform: translateY(-4px);
  }

  .borrar {
    background-color: red;
    transition: all 250ms;
    cursor: pointer;
  }

  .borrar:hover {
    transform: translateY(-4px);
  }

  .notita {
    /* Color amarillo estilo papel */
    color: black;
    display: flex;
    border-radius: 3px;
    width: 100px;
    min-height: 150px;
    min-width: 150px;
    padding: 4px;
    margin: 5px;
    position: relative; /* Necesario para posicionar el pseudo-elemento */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); /* Sombra para dar profundidad */
    font-family: "Comic Sans MS", cursive; /* Ejemplo de fuente manuscrita */
    overflow: hidden;
  }

  .notita p {
    margin: auto;
    text-decoration: underline;
  }

  .notita::before {
    content: "";
    position: absolute;
    width: 20px; /* Tamaño del pliegue */
    height: 20px;
    background-color: rgb(
      0,
      0,
      0
    ); /* Color ligeramente diferente para el pliegue */
    bottom: 0;
    right: 0;
    clip-path: polygon(0 0, 100% 0, 100% 100%);
    box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.2); /* Sombra para el pliegue */
    z-index: 1; /* Coloca el pliegue sobre el borde de la nota */
    border-top: 1px solid rgba(0, 0, 0, 0.1); /* Línea sutil para el borde del pliegue */
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    transform: rotate(90deg);
  }

  #eliminarNota {
    width: 20px;
    height: 20px;
    padding: 0;
    position: absolute;
    margin: 2px;
  }

  .fecha {
    width: 100%;
    padding: 0;
    font-size: 10px;
    position: absolute;
    left: -6px;
    bottom: 0;
    font-weight: bold;
  }

  .todasDelete {
    width: 100px;
    height: 30px;
    margin: 10px auto; /* Centra horizontalmente */
    align-self: center; /* Asegura que esté en la parte inferior */
  }
}
