.tateti {
  background-color: rgb(0, 0, 0);
  height: 50vh;
  width: 100%;
  flex-wrap: nowrap;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.cartel{
  position: absolute; /* Posición absoluta */ 
  top: 50%; /* A mitad del contenedor */
  left: 50%; /* A mitad horizontal si también lo quieres centrar */
  transform: translate(-50%, -50%); /* Ajuste para centrar completamente */
  z-index: 200000;
  background: linear-gradient(rgb(207, 203, 203), rgb(4, 158, 230));
  box-shadow: 2px 2px 30px 2px rgba(245, 222, 179, 0.76);
  width: 100%;
  animation-name: cartel;
  animation-duration: 2s;
  transition: all 250ms ease-in-out;
  text-align: center;
}

.cartel h1{

  color: rgb(255, 255, 255);
  text-shadow:  2px 3px 2px rgba(0, 0, 0, 0.76);
  
}

.equipos {
  display: flex;
  position: relative;
  top: -15px;
}

.jugador {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  padding: 0;
  margin: 0 5px;
}

.jugador span {
  margin: 0px 10px;
  font-size: 50px;
  padding: 0;
}

.jugador p {
  margin: 2px;
  padding: 0;
  font-size: 12px;
}

.jugador1 {
  color: white;
}

.jugador1Seleccionado {
  color: brown;
  transform: sc
}

.jugador2 {
  color: white;
}

.jugador2Seleccionado {
  color: brown;
}

.board {
  display: grid;
  grid-template-columns: repeat(3, 100px);
  grid-gap: 5px;
  position: relative;
  background-color: black;
  border: 5px solid white;
  box-shadow: 0px 0px 10px 3px skyblue;
  border-radius: 15px;
  padding: 5px;
}

.cuadrado {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  cursor: pointer;
}

.par{

  background: black;
}

.impar{
  background-color: rgb(17, 17, 17);
}

.circle {
  font-size: 80px;
  font-weight: lighter;
  color: aqua;
  text-shadow:  2px 3px 2px rgba(0, 0, 0, 0.76);
}
.equis {
  font-size: 80px;
  font-weight: lighter;
  color: rgb(255, 0, 0);
}

.line {
  position: absolute;
  border: 5px solid white;
  z-index: 10;
  box-shadow: 0px 0px 10px 3px skyblue;
  animation-duration: 1s;
}

.horizontal-top {
  top: 15%;
  width: 100%;
  animation-name: line;
}

.horizontal-middle {
  top: 50%;
  width: 100%;
  animation-name: line;
}

.horizontal-bottom {
  top: 82%;
  width: 100%;
  animation-name: line;
}

.vertical-left {
  left: 15%;
  top: 0;
  transform: rotate(90deg);
  transform-origin: left;
  width: 100%;
  animation-name: line;
}

.vertical-middle {
  left: 50%;
  width: 100%;
  transform: rotate(90deg);
  transform-origin: left;
  animation-name: line;
}

.vertical-right {
  left: 82%;
  width: 100%;
  transform: rotate(90deg);
  transform-origin: left;
  animation-name: line;
}

.diagonal-main {
  top: 10px;
  left: 0%;
  width: 130%;
  transform-origin: left;
  transform: rotate(45deg);
  animation-name: lines;
}

.diagonal-counter {
  top: 10px;
  right: 0;
  transform-origin: right;
  animation-name: lines;
  transform: rotate(-45deg);
}

@keyframes line {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}


@keyframes lines {
  from {
    width: 0%;
  }
  to {
    width: 130%;
  }
}

@keyframes carte {

  from {
    transform: scale(0);
  }
  to {
    transform: scale(100%);
  }
}


@media (min-width: 768px) {
}
/* Estilos para escritorio (pantallas grandes) */
@media (min-width: 1024px) {
}
