
/* Estilos base para dispositivos móviles */




/* Estilos para tabletas (pantallas medianas) */

@media (min-width: 768px){


}

/* Estilos para escritorio (pantallas grandes) */
@media (min-width: 1024px){

.home{
    margin: auto;
}

}