/* Estilos base para dispositivos móviles */

.boxLogin{
  min-height: 100vh;
  display: flex;
  overflow-y: hidden;
  


}

.login{

    width: 100%;
    background: rgba(13, 164, 202, 0.123);
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 7px;
    height: auto;
    margin: auto;
    padding: 5px;
}
.user {
  background: rgb(37, 68, 80);
  height: 120px;
  width: 120px;
  margin: auto;
  position: relative;
  top: -25px;
  border-radius: 50%;
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
}

#pass,
#name {
  background-color: rgba(224, 224, 224, 0.692);
  color: rgb(0, 0, 0);
  
  padding: 3px;
  border-radius:  0 5px 5px 0;
}

.input-group{
  display: flex;
  flex-direction: row;
  padding: 5px;
  margin: 5px;
  height: auto;
  font-size: 25px;
  border-radius: 15px;
}



.input-group-append {
  background-color: rgba(37, 68, 80, 0.692);
  color: white;
  padding: 5px;
  min-width:40px;
  text-align: center;
border-radius: 5px 0 0 5px;  
}

.botonLogin {
  background-color: rgb(37, 68, 80);
  border: none;
  height: 40px;
  border-radius: 8px;
  color: azure;
  width: 80px;
  margin: 18px;
}

.botonLogin:hover {
  background-color: rgba(37, 45, 80);
}

#botonBox{

  text-align: center;
}
  /* Estilos para tabletas (pantallas medianas) */
  
  @media (min-width: 768px) {
  
  }
  
  /* Estilos para escritorio (pantallas grandes) */
  @media (min-width: 1024px) {
   
  

  }
  