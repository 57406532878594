/* Estilos base para dispositivos móviles */

/* Estilos para tabletas (pantallas medianas) */

@media (min-width: 768px) {
  .cursor-light {
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 9999;
  }

  .light {
    position: absolute;
    width: 300px;
    height: 300px;
    background: radial-gradient(
      circle,
      rgba(7, 255, 214, 0.144) 0%,
      rgba(255, 255, 255, 0) 50%
    );
    border-radius: 50%;
    transition: transform 0.1s ease;
    mix-blend-mode: color-dodge;
  }
}

/* Estilos para escritorio (pantallas grandes) */
@media (min-width: 1024px) {
  .cursor-light {
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 9999;
  }

  .light {
    position: absolute;
    width: 500px;
    height: 500px;
    background: radial-gradient(
      circle,
      rgba(2, 46, 43, 0.432) 0%,
      rgba(255, 255, 255, 0) 50%
    );
    border-radius: 50%;
    mix-blend-mode: color-dodge;
    transition: transform 0.1s ease;
  }
}
