/* Estilos base para dispositivos móviles */
.headerSmall {
  background-color: rgba(128, 128, 128, 0.144);
  padding: 10px;
  border-radius: 10px;
  width: 100%;
  
}
.presentationSmall {
  display: flex;
  flex-direction: row;
  margin: auto;
  justify-content:space-around;
  align-items: center;
}
.contentImgSmall img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 4px solid rgb(4, 202, 202);
}
.presentationSmall h1 {
  margin: 2px auto 6px auto;
}

.myListSmall {
  list-style: none;
}
.iconSmall {
  margin: 5px;
  color: rgb(4, 202, 202);
  cursor: pointer;
  border: 1px solid rgb(4, 202, 202);
  padding: 4px;
  border-radius: 15%;
  transition: all 200ms;
  margin-left: 20px;
}
.datosSmall {
  margin-top: 35px;
}
.iconSmall:hover {
  background-color: rgb(4, 202, 202);
  color: black;
}

/* Estilos para tabletas (pantallas medianas) */

@media (min-width: 768px) {
}

/* Estilos para escritorio (pantallas grandes) */
@media (min-width: 1024px) {
  .headerSmall {
    width: 100%;
    margin: 0 auto;
    padding: auto;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom right, #012220, #000000, #000000);
    border-width: 3px;
    border-style: solid;
    border-radius: 30px;
    border-image: linear-gradient(to right, #00000000,#01222085, rgb(4, 202, 202)) 1;
  }

  
}
