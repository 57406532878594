.lugar {
  margin: 50px auto;
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
  gap: 10px;
}

.range {
  display: flex;
  max-width: 50%;
  margin: auto;
  flex-direction: column;
}

.range span,
.range label {
  color: black;
}

.fotoCont {
  display: flex;
  max-width: 250px;
  height: auto;
  background-color: white;
  border-radius: 15px;
}

.fotoCont img {
  object-fit: cover;
  width: 100%;
  height: auto;
}

.mg {
  display: flex;
  position: relative;
  left: 80%;
  top: 5%;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
}

.description {
  text-align: start;
  padding-left: 15px;
  height: auto;
}

.description h5 {
  color: black;
  font-size: 18px;
  margin: 0;
}

.description p {
  margin: 0;
  color: black;
}

.icons {
  color: rgb(155, 153, 153);
  border-color: aliceblue;
  padding: 3px;
  border-radius: 5px;
  border: 1px solid rgb(155, 153, 153);
  background-color: rgba(
    173,
    173,
    173,
    0.801
  ); /* El contorno del ícono tendrá el color deseado */
  cursor: pointer;
  transition: all 250ms;
}

.icons:hover {
  color: rgb(192, 0, 0);
  border: 1px solid rgb(192, 0, 0);
  background-color: rgb(
    255,
    255,
    255
  ); /* El contorno del ícono tendrá el color deseado */
}

.corazonActivo {
  color: rgb(192, 0, 0);
  border: 1px solid rgb(192, 0, 0);
}

.corazonInactivo {
  color: rgb(0, 0, 0);
  border-color: rgb(19, 19, 19);
}

.articulo {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border: 1px solid wheat;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
}

.eliminar {
  background-color: rgb(92, 19, 19);
  border-radius: 6px;
  padding: 5px;
  color: white;
  margin: 10px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.eliminar:hover {
  background-color: rgb(190, 36, 36);
}

.botonera,
.boton {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  width: 100%;
  margin: 5px 3px;
}

.sumar {
  width: 100%;
  height: 30px;
  background-color: rgb(206, 30, 30);
  color: white;
  margin: 5px;
  border-radius: 5px;
  cursor: pointer;
}

.sumar:hover {
  background-color: rgb(71, 8, 32);
}

.sumar:active {
  transform: scale(0.8);
}

.sumarMitad {
  width: 50%;
  height: 30px;
  background-color: rgb(13, 97, 10);
  color: white;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px 0;
}

.sumarMitad:hover {
  background-color: rgb(64, 150, 61);
}
.eliminarMitad {
  width: 50%;
  height: 30px;
  background-color: rgb(206, 30, 30);
  color: white;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px 0;
}

.eliminarMitad:hover {
  background-color: rgb(245, 87, 87);
}

/* Estilos para tabletas (pantallas medianas) */

@media (min-width: 768px) {
}
/* Estilos para escritorio (pantallas grandes) */
@media (min-width: 1024px) {
}
