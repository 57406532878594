/* Estilos base para dispositivos móviles */

.renders {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
}

.cerrarModal {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 5px;
  font-size: 30px;
  background-color: rgb(71, 7, 7);
  width: 50px;
  height: 50px;
  border-radius: 5px;
  z-index: 10001;
}

.card {
  width: 100%;
  height: auto;
  border: 2px solid rgba(255, 255, 255, 0.226);
  border-radius: 3%;
  margin: 12px 0;
  padding: 2px;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 2fr;
  gap: 5px;
}

.left-column {
  align-items: center;
  justify-content: center;
  margin: auto;
  height: auto;
}

.left-column i {
  border-radius: 3%;
  max-width: 150px;
  margin: 1px 3px;
}

.right-column {
  display: grid;
  align-items: center;
  width: 100%;
  border-radius: 25px;
  padding: 0 5px;
}

.right-column h4 {
  margin: 1px;
  font-size: 18px;
}

.right-column p {
  margin: auto;
  padding: 3px;
}

.modal {
  margin: auto !important;
  
}

.modal img {
  max-width: 90%;
  max-height: 90%;
  margin: auto;
  border: 2px solid rgba(255, 255, 255, 0.315);
  border-radius: 25px; /* Opcional: agregar borde */
}

.encabezado {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
}
.seleccion {
  color: rgba(255, 255, 255, 0.295);
  transition: all 200ms;
  font-size: 12px;
}
.seleccion:hover {
  color: rgb(4, 202, 202);
}

.probando {
  display: flex;
  flex-direction: column;
}

/* Estilos para tabletas (pantallas medianas) */

@media (min-width: 768px) {
  .card {
    width: 100%;
    height: auto;
    border: 2px solid rgba(255, 255, 255, 0.11);
    border-radius: 10px;
    margin: 12px 0;
    padding: 15px;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 0.5fr 4fr;
  }

  .left-column {
    align-items: center;
    justify-content: center;
    margin: auto;
    height: auto;
  }

  .left-column a > i {
    margin: 5px;
    font-size: 50px;
    border-radius: 10px;
  }

  .right-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 25px;
    padding: 0 5px;
  }
  .right-column h4 {
    font-size: 22px;
  }

  .right-column p {
    font-size: 17px;
    padding: 0;
  }
  .modal {
    margin: auto !important;
  }

  .cerrarModal:hover {
    background-color: rgb(192, 10, 10);

  }

  .modalContent img {
    max-width: 90%;
    max-height: 100%;
    border: 2px solid rgba(255, 255, 255, 0.315);
    border-radius: 25px; /* Opcional: agregar borde */
  }

  .encabezado {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
  }
  .seleccion {
    color: rgba(255, 255, 255, 0.295);
    transition: all 200ms;
  }
  .seleccion:hover {
    color: rgb(4, 202, 202);
  }

  .probando {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    padding: 10px;
  }
}

/* Estilos para escritorio (pantallas grandes) */
@media (min-width: 1024px) {

  .modal {
    margin: auto;
    background-color: rgba(0, 0, 0, 0.932);
    text-align: center;
    z-index: 1000;
    transition: all 300ms;
    cursor: pointer;
  }
}
