/* Estilos base para dispositivos móviles */
.header {
  background-color: rgba(128, 128, 128, 0.144);
  padding: 10px;
  border-radius: 25px;
}
.idioma {
  position: relative;
  left: 90%;
  font-size: 12px;
  width: 41px;

}

.lenguaje {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 3px;
  width: 40px;
  height: auto;
  border-radius: 20px;
  background: linear-gradient(
    to bottom,
    rgba(255, 0, 0, 0.603) 33.33%,
    rgba(255, 255, 0, 0.685) 33.33%,
    yellow 66.66%,
    /* Segundo color, cubriendo hasta el 66.66% del área */
      rgba(255, 0, 0, 0.726) 66.66%
  );
  border: none;
  transition: all 500ms;
}

.lenguaje i {
  color: rgb(146, 245, 220);
  font-size: 9px;
}

.lengUsa i {
  color: rgb(146, 245, 220);
  font-size: 9px;
}

.lengUsa {
  display: flex;
  justify-content: center;
  margin-top: 3px;
  align-items: center;
  text-align: center;
  width: 40px;
  height: auto;
  border-radius: 20px;
  background: linear-gradient(
      to bottom,
      #b22234 0%,
      #b22234 15.4%,
      white 15.4%,
      white 30.8%,
      #b22234 30.8%,
      #b22234 46.2%,
      white 46.2%,
      white 61.6%,
      #b22234 61.6%,
      #b22234 77%,
      white 77%,
      white 92.4%,
      #b22234 92.4%,
      #b22234 100%
    ),
    linear-gradient(
      to bottom,
      #3c3b6e 0%,
      #3c3b6e 50%,
      transparent 50%,
      transparent 100%
    ),
    linear-gradient(
      to right,
      #3c3b6e 0%,
      #3c3b6e 40%,
      transparent 40%,
      transparent 100%
    );
  border: none;
  transition: all 500ms;
}

.es {
  position: relative;
  left: -40%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #012220;
  margin: 0;
  transition: all 350ms;
  border: 1px solid rgba(255, 255, 255, 0.514);
  text-align: center;
  cursor: pointer;
}

.en {
  position: relative;
  left: 40%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #012220;
  border: 1px solid rgba(255, 255, 255, 0.514);
  margin: auto;
  transition: all 350ms;
  cursor: pointer;
}

.presentation {
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
  align-items: center;
}
.contentImg img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 4px solid rgb(4, 202, 202);
}
.presentation h1 {
  margin: 2px auto 6px auto;
}
.profesiones {
  display: flex;
  font-size: 11px;
  justify-content: space-around;
  color: rgb(4, 202, 202);
  width: 100%;
}
.profesiones h3 {
  margin: 0 1px;
}
.slogan {
  margin-top: 10px;
}
.myList {
  list-style: none;
}
.icon {
  margin: 5px;
  color: rgb(4, 202, 202);
  cursor: pointer;
  border: 1px solid rgb(4, 202, 202);
  padding: 4px;
  border-radius: 15%;
  transition: all 200ms;
  margin-left: 20px;
}
.datos {
  margin-top: 35px;
}
.icon:hover {
  background-color: rgb(4, 202, 202);
  color: black;
}

/* Estilos para tabletas (pantallas medianas) */

@media (min-width: 768px) {
}

/* Estilos para escritorio (pantallas grandes) */
@media (min-width: 1024px) {
  .header {
    width: 80%;
    margin: auto;
    padding: auto;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom right, #012220, #000000, #000000);
  }

  .profesiones {
    width: 50%;
  }

  
}
