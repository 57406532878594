/* Estilos base para dispositivos móviles */
.imgRender {
    width: 100%;
    height: auto;
    border: 1px solid grey;
    padding: 5px;
    margin: 80px  auto;
  }
  
  .boxFormRender {
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .formRender {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .custom-file-upload {
    display: inline-block;
    background-color: rgb(192, 190, 190);
    color: black;
    width: 100px;
    padding: 5px;
    border-radius: 8px;
  }
  
  .formInputRender[type="file"] {
    display: none;
  }
  .btnAgregarRender {
    background-color: rgb(37, 68, 80);
    border: none;
    height: 40px;
    border-radius: 8px;
    color: azure;
    width: 75px;
    margin: 5px;
  }
  .btnEliminarRender {
    background-color: rgb(80, 37, 37);
    border: none;
    height: 40px;
    border-radius: 8px;
    color: azure;
    width: 75px;
    margin: 5px;
  }
  
  .btnAgregarRender:hover {
    background-color: rgba(37, 45, 80);
  }
  
  .btnEliminarRender:hover {
    background-color: rgb(150, 13, 13);
  }
  
  .boxMuestraRender {
    max-width: 150px;
    margin: 10px;
  }
  
  .listaRenderEditar {
    list-style: none;
    text-decoration: none;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
  
  .listaRenderEditar li {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .botonera {
    display: flex;
    flex-direction: row;
  
    padding: 0;
  }
  
  /* Estilos para tabletas (pantallas medianas) */
  
  @media (min-width: 768px) {
  }
  
  /* Estilos para escritorio (pantallas grandes) */
  @media (min-width: 1024px) {
  
    .boxMuestraRender {
      max-width: 100%;
      margin: 30px;
    }
  
    .formRender{
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .formPerfilRender {
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .listaRenderEditar {
   padding: 0;
      display: flex;
     flex-direction: row;
     justify-content: center;
  
    }
  
  
  }
  